import React from 'react'
import kkk_logo from './assets/kkk_logo.png'

import grren_circle from './assets/green_circle.png'
import leaf_img1 from './assets/leaf_img1.png'
import downbtn from './assets/downbtn.png'
import contact_us from './assets/contact_us.svg'



const Home = () => {
    const date = new Date()
    const years = date.getFullYear()
    return (
        <>
            <div className='farmerPic'></div>
            <div className='mainDiv'>
                <div className='navDiv'>
                    <nav class="navbar navbar-expand-lg ">
                        <div class="container">
                            <div class="navbar-nav me-5">
                                <img src={kkk_logo} />
                            </div>
                            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                                <span class="navbar-toggler-icon"></span>
                            </button>
                            <div class="collapse navbar-collapse" id="navbarText">
                                <ul class="navbar-nav me-auto mb-2 mb-lg-0" >
                                    <li class="nav-item">
                                        <a class="nav-link active" aria-current="page" href="#">Home</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" href="#features">Features</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" href="#">Product</a>
                                    </li>
                                </ul>
                                <span class="navbar-text">
                                    <a className='btn contact_us_btn' href='#contactus'>
                                        Contact us
                                    </a>
                                </span>
                            </div>
                        </div>
                    </nav>
                </div>

                <div className='home_1' style={{ marginTop: '150px' }}>
                    <h1><span className='img_bg_home1' >Empowering</span> Farmers for A</h1>
                    <h1>Prosperous Tomorrow</h1>
                    <div className='mt-4'>Seeds, Suppor, and Machinery Your Path to Agricultural Success. At Agriplant,</div>
                    <div>we're dedicated to revolutionizing agriculture in Gujarat.</div>
                    <a className='btn contact_us_btn mt-5' href='#contactus'>
                        Contact us
                    </a>
                </div>

                <div className='downbtn'>
                    <img src={downbtn} className='down_btn' />
                </div>

                <div className='home_2' id='features'>
                    <p className='feature_text'>Feature</p>

                    <h3 className='feature_title'>Comprehensive Agricultural Solutions</h3>
                    <p >Through Guidance and Cutting-Edge Solutions.</p>
                    <div className='container'>
                        <div className='row card_feature'>
                            <div className='col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 col-xxl-3 mb-2'>
                                <div className='card p-3 pt-4 ' style={{ borderColor: "#436826" }}>
                                    <i className='bx bx-support bx-lg' style={{ color: "#436826" }}></i>
                                    <h5>Support & Consultancy</h5>
                                    <p>Elevating agricultural success through dedicated support and expert consultancy for sustainable and thriving farming practices.</p>
                                </div>
                            </div>
                            <div className='col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 col-xxl-3 mb-2'>
                                <div className='card p-3 pt-4' style={{ borderColor: "#E85222" }}>
                                    <i className='bx bx-purchase-tag-alt bx-lg' style={{ color: "#E85222" }}></i>
                                    <h5>Quality product</h5>
                                    <p>Ensuring agricultural excellence with our commitment to delivering quality products that empower farmers and sustainable growth.</p>
                                </div>
                            </div>
                            <div className='col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 col-xxl-3 mb-2'>
                                <div className='card p-3 pt-4' style={{ borderColor: "#AD26AF" }}>
                                    <i class='bx bxs-package bx-lg' style={{ color: "#AD26AF" }}></i>
                                    <h5>Fast Ddelivery</h5>
                                    <p>Timely and efficient delivery, ensuring farm essentials reach you swiftly for seamless agricultural operations.</p>
                                </div>
                            </div>
                            <div className='col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 col-xxl-3 mb-2'>
                                <div className='card p-3 pt-4' style={{ borderColor: "#0D699C" }}>
                                    <i className='bx bx-world bx-lg' style={{ color: "#0D699C" }}></i>
                                    <h5>Modern product</h5>
                                    <p>Pioneering the future of agriculture with cutting-edge, modern products that revolutionize farming practices.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className='contactus'>
                    <div className='container' id='contactus'>
                        <div className='text-center'><p className='feature_text'>Contact Us</p></div>
                        <div className='row mt-5'>
                            <div className='col-12 col-sm-12 col-md-6 p-lg-4 left'>
                                <img src={contact_us} className='img-fluid' />
                                <h4 className='mt-5'>Get in Touch with Us</h4>
                                <p>If you have any questions, inquiries, or need assistance with our service, feel free to reach out. Our dedicated team is here to provide you with the support and information. Together, let's cultivate a brighter future.</p>
                            </div>
                            <div className='col-12 col-sm-12 col-md-6 p-lg-4 right'>
                                <h4 className='mt-5'>Call On</h4>
                                <div>
                                    <i className='bx bxs-phone'></i> +91 93282 28901
                                </div>
                                <div>
                                    <i className='bx bxs-phone'></i> +91 94996 54837
                                </div>
                                <h4 className='mt-5'>Email On</h4>
                                <div>
                                    <i className='bx bxl-gmail'></i> kisaankrushikendra@gmail.com
                                </div>
                                <h4 className='mt-5'>Address</h4>
                                <div>
                                    <i className='bx bx-buildings'></i> KISAAN KRUSHI KENDRA, 120, The Urbania, palm road, Kudasan, Gandhinagar-382421
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='footer'>
                    <div className='ftr'>
                        <img src={kkk_logo} />
                        <p className='mt-5'>© {years} Kisaan Krushi Kendra </p>
                        <p className='bb_ad'>Managing CRM and website by <a href="https://www.bitbrains.in" target='_blank'>Bitbrains.in</a></p>
                    </div>
                </div>

                <img src={grren_circle} className='img_grren_circle' />
                <img src={leaf_img1} className='img_leaf1' />
            </div>
        </>
    )
}

export default Home